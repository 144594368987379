export var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export var fullNameReg = /^([^|&;$%#!*?"~\\\/<>()^№:@._=[\]{}\+0-9]*\s)[^|&;$%#!*?"~\\\/<>()^№:@._=[\]{}\+0-9]+$/;
export var nameReg = /[|&;$%#!*?"~\\\/<>()^№:@._=[\]{}\+0-9]|\s{2,}/;
export var phoneReg = /^[0-9]*$/;
export var getCoefficientByAmount = function getCoefficientByAmount(value, minDeposit) {
  var coefficient = 0;

  if (value === minDeposit) {
    coefficient = 2;
  } else if (value > minDeposit && value < minDeposit * 2) {
    coefficient = 2.5;
  } else if (value >= minDeposit * 2 && value < minDeposit * 4) {
    coefficient = 3.5;
  } else if (value >= minDeposit * 4 && value < minDeposit * 20) {
    coefficient = 5;
  } else if (value >= minDeposit * 20) {
    coefficient = 5.5;
  }

  return coefficient;
};
export var getCoefficientByAmountV2 = function getCoefficientByAmountV2(value, minDeposit) {
  var coefficient = 0;

  if (value === minDeposit) {
    coefficient = 10.24;
  } else if (value > minDeposit && value < minDeposit * 2) {
    coefficient = 8.74;
  } else if (value >= minDeposit * 2 && value < minDeposit * 4) {
    coefficient = 7.24;
  } else if (value >= minDeposit * 4 && value < minDeposit * 20) {
    coefficient = 5.74;
  } else if (value >= minDeposit * 20) {
    coefficient = 4.24;
  }

  return coefficient;
};
export var formatNumber = function formatNumber(number, cutZeros, isMobile, funnel_language) {
  var numberFormat = Intl.NumberFormat('en-US').format(number);

  if (isMobile && cutZeros && number >= 1000) {
    return Math.round(number / 100) / 10 + 'k';
  }

  if (!isMobile && funnel_language === 'it') {
    if (number >= 10000) {
      return Intl.NumberFormat('it-IT').format(number);
    }

    return String(number).replace(/[.]/g, ',');
  }

  if (!isMobile && number >= 10000) {
    return numberFormat;
  }

  return number;
};
export var roundToDecimal = function roundToDecimal(value) {
  return value / 100;
};
export var formulaCalc = function formulaCalc(number) {
  var result = 5.13 * number + 24;
  return result % 1 === 0 ? result : result.toFixed(2);
};
export var getPercentFromNumber = function getPercentFromNumber(base, part) {
  return (100 * part / base).toFixed(2);
};
export var isTouchDevice = function isTouchDevice() {
  try {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

    var mq = function mq(query) {
      return window.matchMedia(query).matches;
    };

    if ('ontouchstart' in window || typeof window.DocumentTouch !== 'undefined' && document instanceof window.DocumentTouch) {
      return true;
    }

    return mq(['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(''));
  } catch (e) {
    console.error('(Touch detect failed)', e);
    return false;
  }
};
export var handleGesture = function handleGesture(touchstartX, touchstartY, touchendX, touchendY) {
  var delx = touchendX - touchstartX;
  var dely = touchendY - touchstartY;

  if (Math.abs(delx) > Math.abs(dely)) {
    if (delx > 0) return 'right';else return 'left';
  } else if (Math.abs(delx) < Math.abs(dely)) {
    if (dely > 0) return 'down';else return 'up';
  } else return 'tap';
};
export var loadScript = function loadScript(d, s, id, jsSrc, cb, onError) {
  var element = d.getElementsByTagName(s)[0];
  var fjs = element;
  var js = element;
  js = d.createElement(s);
  js.id = id;
  js.src = jsSrc;

  if (fjs && fjs.parentNode) {
    fjs.parentNode.insertBefore(js, fjs);
  } else {
    d.head.appendChild(js);
  }

  js.onerror = onError;
  js.onload = cb;
}; // TODO: make scalabale object for different type of formatting

export var formatValue = function formatValue(value, multiplier) {
  return new Intl.NumberFormat('en-US').format(value * multiplier / 100);
};